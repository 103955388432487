import React, { useState } from "react";
import Contact from "./contact";
import Button from "@mui/material/Button";
import "../components/about.css";
import { Box } from "@mui/material";
import { createTheme, theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

export default function About(props) {
  const [showContactForm, setShowContactForm] = useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#21248d",
      },
      secondary: {
        main: "#6100FF",
      },
    },
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: props ? props.backgroundColor : theme.palette.primary.main,
          color: "blue",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          my: 2,
        }}
      >
        <Card sx={{ maxWidth: 325, mx: 1, my: 1 }} className="cardContainer">
          <CardMedia
            sx={{
              width: 200,
              height: 220,
              objectFit: "contain",
              borderRadius: "50%",
            }}
            image={require("/Users/shadsachikonye/shads-dev-1/src/resources/hero.jpg")}
            title="image of shad"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              <ul>
                <li>&#128104;&#127997; Shad</li>
                <li> &#128205; Zurich / London </li>
                <li>&#128187; FullStack Developer</li>
              </ul>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                color="text.primary"
              >
                Things I love{" "}
              </Typography>
              <ul>
                <li> &#127947; Gym </li>
                <li>&#9917; Football (Arsenal) </li>
                <li>&#127926; Music</li>
              </ul>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="medium"
              onClick={() => setShowContactForm(!showContactForm)}
            >
              Contact
            </Button>
          </CardActions>
        </Card>
        {showContactForm && (
          <Contact
            showContactForm={showContactForm}
            onClose={() => setShowContactForm(!showContactForm)}
          />
        )}
      </Box>
    </>
  );
}
