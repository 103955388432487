import React from "react";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import AppBar from "@mui/material/AppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import AdbIcon from "@mui/icons-material/Adb";
import { MenuList } from "@mui/material";

export default function Navigation() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#6100FF",
      },
      secondary: {
        main: "#ffca28",
      },
    },
  });

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
  };
  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "inherit" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <AdbIcon
              component="a"
              href="/"
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              SHAD SACHIKONYE
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  borderRadius: "16px",
                }}
              >
                <MenuItem
                  key="home"
                  onClose={handleCloseNavMenu}
                  component="a"
                  href="/"
                >
                  <Typography textAlign="center">HOME</Typography>
                </MenuItem>

                {/* <MenuItem
                  key="blog"
                  onClose={handleCloseNavMenu}
                  component="a"
                  href="/blog"
                >
                  <Typography textAlign="center">BLOG</Typography>
                </MenuItem> */}
              </Menu>
            </Box>

            <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                key="home"
                component="a"
                href="/"
                size="large"
                onClose={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                HOME
              </Button>
              {/* 
              <Button
                key="blog"
                component="a"
                href="/blog"
                size="large"
                onClose={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                BLOG
              </Button> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
