import * as React from "react";
import Resume from "./components/resume";
// import Thoughts from "./components/thoughts";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Resume />} />
        <Route exact path="/resume" element={<Resume />} />
        {/* <Route exact path="/blog" element={<Thoughts />} /> */}
        <Route exact path="/*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
