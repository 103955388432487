import React from "react";
import "../components/contact.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";

const theme = createTheme({
  palette: {
    closeButton: {
      standard: "#E5606A",
      hover: "#4EE499",
    },
  },
});

const SERVICE_ID = "service_lsknwuf";
const TEMPLATE_ID = "template_erfxxfq";
const PUBLIC_KEY = "AYZADRWZib9lbBecq";

export default function Contact({ showContactForm, onClose }) {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops! Something went wrong!",
          text: error.text,
        });
      }
    );
    e.target.reset();
    setTimeout(() => onClose(), 2000);
  };

  if (!showContactForm) return null;

  const handleClose = (e) => {
    if (e.target.id === "contactWrapper") {
      onClose();
    }
    onClose();
  };
  return (
    <>
      <div className="contactPage" id="contactWrapper">
        <div className="contactContainer" id="contactContainer">
          <form onSubmit={(e) => handleOnSubmit(e)}>
            <h3>
              Hi{" "}
              <span role="img" aria-label="hand waving" alt="hand waving">
                &#128075;
              </span>
            </h3>
            <div className="fields">
              <label name="first_name">First Name:</label>
              <input type="text" name="first_name" id="first_name" required />
              <label name="surname">Last Name:</label>
              <input type="text" name="surname" id="surname" required />
              <label name="user_email">Email:</label>
              <input type="email" name="user_email" id="user_email" required />
              <label name="message">Message:</label>
              <textarea
                type="text"
                id="message"
                name="message"
                rows={5}
                cols={50}
              />
            </div>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
              size="medium"
              sx={{ mx: 2 }}
            >
              {" "}
              Send{" "}
            </Button>
            <Button
              variant="contained"
              className="closeContact"
              type="close"
              onClick={handleClose}
              endIcon={<CancelIcon />}
              size="medium"
              sx={{ mx: 2, bgcolor: "#E5606A" }}
            >
              close
            </Button>{" "}
          </form>
        </div>
      </div>
    </>
  );
}
