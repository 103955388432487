import React, { useState } from "react";
import Navigation from "./navigation";
import "../components/resume.css";
import hero from "../resources/hero.jpg";
import styled from "styled-components";
import About from "./about";
import AboutFlip from "./aboutFlip";

export default function Resume() {
  const [flip, setFlip] = useState(false);
  const [showCard, setShowCard] = useState(true);

  const StyledSnippet = styled.p`
    color: white;
    font-style: italic;
    line-height: 1;
  `;

  const StyledJobDescription = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      list-style: circle;
    }

    a {
      color: #35c87e;
    }
  `;

  const StyledJobTitle = styled.h4`
    color: white;
    font-style: Capitalize;
    text-align: left;
    margin-bottom: 0;
  `;
  return (
    <>
      <Navigation />
      <div className="resumePageContainer">
        <div className="resumePage">
          <div
            className="flipCard"
            onMouseEnter={() => {
              setFlip(true);
              setShowCard(false);
            }}
            onMouseLeave={() => {
              setFlip(false);
              setShowCard(true);
            }}
          >
            {showCard && <About backgroundColor="#3e42c2" />}
            {flip && <AboutFlip backgroundColor="#3e42c2" />}
          </div>
          <div className="jobs">
            <div className="resume-heading">
              <h2 className="sectionHeader">The Last 5 years...</h2>
              <StyledSnippet>
                "Solving today's problems to create more interesting problems
                for tomorrow."
              </StyledSnippet>
            </div>
            <StyledJobTitle>
              Product Solutions Engineer - Google Payments Platform (Nov 2022 -
              Present)
            </StyledJobTitle>
            <StyledJobDescription>
              <li>
                {" "}
                Owned E2E partner integrations in EMEA with Google Payments
                Platform APIs by implementing backend infrastructure, creating
                APIs, documentation and provided technical guidance for external
                partners during onboarding and thereafter postlaunch support
                where necessary.
              </li>
              <li>
                {" "}
                Launched EMEA e-invoicing integrations in Poland, Turkey,
                Serbia, France which protected over $1B in revenue for Google by
                observing local regulatory requirements for revenue
                processing/reporting.{" "}
              </li>
              <li>
                {" "}
                Balanced business and partner needs whilst respecting internal
                and external technical constraints to provide cutting edge
                technical solutions to real world issues.
              </li>
            </StyledJobDescription>
            <StyledJobTitle>
              Partner Engineer - Partner Engineer for Google Assistant on
              Chrome. (Oct 2021 - Nov 2022)
            </StyledJobTitle>
            <StyledJobDescription>
              {" "}
              <li>
                Helped land{" "}
                <a href="https://blog.google/products/chrome/automated-password-changes/">
                  Automated Password Change
                </a>{" "}
                on Chrome for Android to service more users
              </li>
              <li>
                Increased user reach by +300% via more availability on top sites
                for users and more accesible feature availability
              </li>
              <li>
                Coordinated the development efforts, time lines and quality
                assurance via code quality reviews and best practice sharing
              </li>
              <li>
                Lead team of developers and QA testers to ensure quality and
                implementation of features.
              </li>
            </StyledJobDescription>
            <StyledJobTitle>
              Product Specialist (Apr 2019 - Oct 2021)
            </StyledJobTitle>
            <StyledJobDescription>
              <li>
                Technical expert working with Google’s largest clients to
                provide technical product solutions.
              </li>
              <li>Resolved product bugs and provided product solutions</li>
              <li>
                Enabled customers to optimise their Google products and
                protected Google’s revenue.
              </li>
              <li>
                Maximised customer experience by working cross functionally with
                multiple stakeholders to accelerate resolution times for
                sensitive issues, achieving a 24hr TAT for over over 80%+ of the
                time.
              </li>
              <li>
                Maximised customer experience and accelerated resolution times
                for sensitive issues
              </li>
            </StyledJobDescription>
            <StyledJobTitle>
              Market Operations Lead (Jan 2018 - Apr 2019)
            </StyledJobTitle>
            <StyledJobDescription>
              Real-time operations lead for 30+ agents for the UKI vertical
              worth ~£650m.
              <li>
                Leveraged strategic insights & operational rigor to enable
                external facing agents to achieve KPIs and customer satisfaction
                targets
              </li>
              <li>
                Effectively program managed concurrent initiatives whilst
                interfacing with multiple internal stakeholders
              </li>
              <li>
                Achieved a market first time resolution metric of 81%, an
                increase of 6% points YoY.
              </li>
              <li>
                Resolved strategic and operational problems and identified key
                insights with local context for the UKI market
              </li>
              <li>
                Identified the agent utilisation metric, showcasing the team’s
                excess capacity and how we could better utilise time.
              </li>
              <li>
                Since agent utilisation was low (62%), proposed proactive
                projects which increased utilisation from 62% - 78%, thus
                decreasing the deadweight efficiency loss.
              </li>
              <li>
                Scaled the gTech market operations’ efficiency by streamlining
                operational workflows, extracting an additional ~20hrs weekly
                from the UKI market to cover market overflows, once again
                increasing utilisation.
              </li>
            </StyledJobDescription>
            <StyledJobTitle>
              (Founder) FindMaids Ltd. (Jan 2016 - 2020)
            </StyledJobTitle>
            <StyledJobDescription>
              <li>
                Founded a cleaning and maintenance company whilst in University
                targeted at providing regularly/deep cleaning and maintanence
                for student homes.
              </li>
              <li>
                Cut costs by 40% by negotiating pricing and fees with suppliers,
                whilst ensuring the continuation and enhancements of the supply
                services.
              </li>
              <li>
                Increased Revenue by over 150% through effective PPC, SEO and
                social media online marketing strategies which boosted
                web-traffic and whilst maintaining yield
              </li>
              <li>Doubled monthly revenue by signing the biggest client.</li>
            </StyledJobDescription>
          </div>
        </div>
      </div>
    </>
  );
}
