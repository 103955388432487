import React, { useState } from "react";
import "../components/about.css";
import { Box, CardActions, Button } from "@mui/material";
import { createTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Contact from "./contact";

export default function AboutFlip(props) {
  const [showContactForm, setShowContactForm] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#21248d",
      },
      secondary: {
        main: "#6100FF",
      },
    },
  });

  const spanStyle = {
    fontWeight: "500",
    textTransform: "capitalize",
    color: "#21248d",
    fontSize: "1rem",
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: props ? props.backgroundColor : theme.palette.primary.main,
          color: "blue",
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          my: 2,
        }}
      >
        <Card sx={{ maxWidth: 325, mx: 1, my: 1 }} className="cardContainer">
          <CardMedia
            sx={{
              width: 200,
              height: 220,
              objectFit: "contain",
              borderRadius: "50%",
            }}
            image={require("/Users/shadsachikonye/shads-dev-1/src/resources/hero.jpg")}
            title="image of shad"
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              <ul>
                <li>&#128231; shad@shads.dev</li>
                <li>
                  {" "}
                  &#128451;{" "}
                  <a href="https://github.com/shadsachs?tab=repositories">
                    shadsachs
                  </a>
                </li>
              </ul>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                color="text.primary"
              >
                Tech{" "}
              </Typography>
              <ul>
                <li>
                  <span style={spanStyle}>Backend</span>: Java, Python,
                  Javascript (OOP), Kotlin
                </li>
                <li>
                  <span style={spanStyle}>WebTech</span>: ReactJS, Angular,
                  Javascript, HTML5, CSS, SCSS{" "}
                </li>
                <li>
                  <span style={spanStyle}>Data</span>: SQL
                </li>
              </ul>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="medium"
              onClick={() => setShowContactForm(!showContactForm)}
            >
              Contact
            </Button>
          </CardActions>
        </Card>
        {showContactForm && (
          <Contact
            showContactForm={showContactForm}
            onClose={() => setShowContactForm(!showContactForm)}
          />
        )}
      </Box>
    </>
  );
}
