import React from "react";
import styled from "styled-components";

export default function PageNotFound() {
  const StyledNotFound = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #21248d;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    h1 {
      text-transform: uppercase;
      color: white;
      font-size: 3rem;
      text-align: center;
    }
  `;

  return (
    <>
      {" "}
      <StyledNotFound>
        <h1>Ooops: Page Not Found</h1>
      </StyledNotFound>
    </>
  );
}
